<template>
  <b-modal id="project-details-modal"
           size="xl"
           no-close-on-esc
           no-close-on-backdrop
           hide-footer
           scrollable
           @hidden="resetData"
           title="Project Details"
  >
    <b-overlay
        variant="white"
        class="project-details-modal"
        :show="loading"
        spinner-variant="primary"
        blur="1"
        opacity=".75"
        rounded="sm"
    >
      <ProjectPlan
          v-if="data"
          :attachments="data.project_plan_attachment"
          :projectDetailsId="projectDetailsId"
      />

      <ProjectDetailsSection
          v-if="data"
          :totalArea="data.total_area"
          :google_drive_link="data.project.google_drive_link"
          :projectDetails="data.project_details"
          :projectDetailsId="projectDetailsId"
          :projectId="projectId"
      />

      <!--      table     -->
      <div class="mb-2">
        <div v-for="(table, key, index) in tablesArray" :key="index" class="">
          <div v-if="table.length" class="">
            <TablesList
                ref="TablesList"
                :offerId="-1"
                :typeName="key"
                :readonly="true"
                :withAreaCol="true"
                :windowWidth="windowWidth"
                v-model="tablesArray[key]"
            />
          </div>
        </div>
      </div>

      <FirstSiteVisit
          v-if="data"
          :data="data.project_details"
          :projectDetailsId="projectDetailsId"
      />

    </b-overlay>
  </b-modal>
</template>

<script>
import ProjectService from "@/services/ProjectService";
import TablesList from "@/views/components/seller/table of quantity/TablesList";
import ProjectPlan from "@/views/components/projects/details/ProjectPlan";
import ProjectDetailsSection from "@/views/components/projects/details/ProjectDetailsSection";
import FirstSiteVisit from "@/views/components/projects/details/FirstSiteVisit";

export default {
  name: "ProjectDetailsModal",
  components: {
    FirstSiteVisit,
    ProjectDetailsSection,
    ProjectPlan,
    TablesList
  },
  data() {
    return {
      data: null,
      projectDetailsId: null,
      projectId: null,
      tablesArray: {},
      projectService: new ProjectService(),
      loading: true,
      windowWidth: window.innerWidth
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    resetData() {
      this.data = null
      this.projectDetailsId = null
      this.projectId = null
      this.tablesArray = {}
    },
    open() { // runs from the component that uses this modal
      this.$bvModal.show('project-details-modal');
      this.getData()
    },
    getData() {
      this.loading = true
      this.projectService.getProjectDetails(this.projectDetailsId)
          .then(res => {
            console.log(res)
            Object.keys(res.items).forEach((key) => {
              res.items[key].forEach((item, index) => {
                if (!this.tablesArray[key]) {
                  this.tablesArray[key] = []
                }
                item.index = index + 1
                item.savingSandBlast = false
                item.savingPlanLocation = false
                this.tablesArray[key].push(item)
              })
            })
            this.data = res

          })
          .finally(() => {
            this.loading = false
          })
    },

  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>

<style scoped>
.project-details-modal {
  min-height: 300px;
}
</style>
