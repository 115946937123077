var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',[_vm._v("Project Details")]),_c('div',{},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-form-group',{attrs:{"label":"Offer Approved By Client","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Offer Approved By Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"status-selector",class:_vm.statusError ? 'border-danger-for-v-select' : '',attrs:{"label":"name","placeholder":"Select Status","clearable":false,"loading":_vm.statusListLoader,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusList,"reduce":function (option){ return option.name; }},on:{"input":_vm.checkAttachedFile},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('span',{staticClass:"d-flex option-style",style:({backgroundColor:color, color:'#fff'})},[_vm._v(" "+_vm._s(name)+" ")])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('span',{staticClass:"d-flex pl-2 pr-2",style:({backgroundColor:color, color:'#fff'})},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.localProjectDetails.offer_status),callback:function ($$v) {_vm.$set(_vm.localProjectDetails, "offer_status", $$v)},expression:"localProjectDetails.offer_status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.statusError))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[(_vm.media)?_c('DropZone',{attrs:{"classes":'',"label":'Attach Signed Contract',"labelCols":4,"filesThumbnail":_vm.media,"filesIDs":_vm.mediaId,"hint":'',"maxSize":30,"maxFiles":1},on:{"dataChanged":_vm.dataChanged}}):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-form-group',{attrs:{"label":"Google Drive URL","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"google drive URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"debounce":"1000","state":errors.length > 0 ? false : null,"placeholder":"https://drive.google.com/123"},on:{"update":_vm.updateDriveLink},model:{value:(_vm.localDriveLink),callback:function ($$v) {_vm.localDriveLink=$$v},expression:"localDriveLink"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"href":'https://drive.google.com/',"variant":"primary","target":"_blank"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"LinkIcon","title":"Google Drive"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Total Project Area","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"disabled":"","value":_vm.totalArea,"placeholder":"Total Project Area"}})],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }