<template>
  <div class="">
    <vue-good-table
        mode="remote"
        :pagination-options="{enabled: true}"
        :totalRows="totalRecords"
        :rows="rows"
        :columns="columns"
        :isLoading.sync="isLoading"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'location'" style="width: 250px;display: flex;">
          <p style="text-overflow: ellipsis;max-width: 220px;overflow: hidden;white-space: nowrap">
            {{ props.row.location }}
          </p>
          <feather-icon
              class="ml-1 cursor-pointer"
              v-b-popover.hover.bottom="props.row.location"
              :title="'Location'"
              size="18"
              icon="InfoIcon"
          />
        </span>
        <span v-else-if="props.column.field === 'status'">
        <span class="d-flex justify-content-between"
              :class="{'skeleton-box': props.row.savingProjectStatus}">
           <span>
              <span :style="{color:getProjectStatusColor(props.row.status)}"
              >
                {{ props.row.status }}
              </span>
            </span>
            <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25"/>
              </template>
              <b-dropdown-item
                  v-for="status in statusList "
                  @click="changeOfferStatus(props.row, status.name)"
              >
                <span
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    :style="{color:status.color}"
                >
                  {{ status.name }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
      </span>
        <span v-else-if="props.column.field === 'owner.first_name'"
              class="d-flex align-items-center avatar-class"
        >
        <b-avatar
            size="26"
            :text="avatarText(props.row.owner.first_name + ' ' + props.row.owner.last_name)"
        />
        <span class="ml-1">{{ props.row.owner.first_name + ' ' + props.row.owner.last_name }}</span>
      </span>
        <span v-else-if="props.column.field === 'sales_agent.first_name'"
              class="d-flex align-items-center avatar-class"
        >
        <b-avatar
            size="26"
            :text="avatarText(props.row.sales_agent.first_name + ' ' + props.row.sales_agent.last_name)"
        />
        <span class="ml-1">{{ props.row.sales_agent.first_name + ' ' + props.row.sales_agent.last_name }}</span>
      </span>
        <span v-else-if="props.column.field === 'google_drive_link'">
        <a style="overflow-wrap: anywhere;"
           :href="props.row.google_drive_link"
           target="_blank"
        >
          {{ props.row.google_drive_link }}
        </a>
      </span>
        <span v-else-if="props.column.field === 'action'">
        <b-dropdown
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            dropleft
            no-caret
            variant="secondary"
            class="dropdown-icon-wrapper"
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon"/>
          </template>
          <b-dropdown-item
              :to="{ name: 'update-project', params: { id: props.row.id } }"
          >
            <div class="d-flex">
            <feather-icon icon="EditIcon"/>
            <span class="ml-1">Edit</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="showProjectDetailsModal(props.row)">
            <div class="d-flex">
            <feather-icon icon="BookOpenIcon"/>
            <span class="ml-1">Project Details</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
              @click="$refs.deleteModel.open(props.row.id)"
          >
            <div class="d-flex">
              <feather-icon icon="TrashIcon"/>
              <span class="ml-1">Delete</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </span>
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
      </span>
      </template>
    </vue-good-table>
    <ProjectDetailsModal ref="ProjectDetailsModal"/>
    <general-modal
        ref="deleteModel"
        text="Are you sure you want to delete the project?"
        okText="Yes"
        cancelText="no"
        @result="deleteProject"
    />
  </div>
</template>

<script>
import '@core/scss/vue/libs/vue-good-table.scss';

import ProjectService from "@/services/ProjectService";
import {VueGoodTable} from "vue-good-table";
import Ripple from "vue-ripple-directive";
import {BPagination, VBModal, VBPopover} from "bootstrap-vue";
import ProjectDetailsModal from "@/views/components/projects/details/ProjectDetailsModal";
import GeneralModal from "@/views/components/shared/GeneralModal";

export default {
  name: "ProjectTable",
  components: {
    ProjectDetailsModal,
    VueGoodTable,
    BPagination,
    GeneralModal
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-popover': VBPopover,
  },
  data() {
    return {
      projectService: new ProjectService(),
      rows: [],
      columns: [
        {label: 'Job Order', field: 'job_order', width: '150px'},
        {label: 'Offer Reference', field: 'offer.serial_number'},
        {label: 'Project Name', field: 'offer.project_name', width: '200px'},
        {label: 'Start Date', field: 'created_at', width: '150px'},
        {label: 'Location', field: 'location', width: '200px'},
        {label: 'Project Status', field: 'status', width: '200px'},
        {label: 'Project Owner', field: 'owner.first_name', width: '250px'},
        {label: 'Sales Agent', field: 'sales_agent.first_name', width: '250px'},
        {label: 'Google Drive Link', field: 'google_drive_link', width: '300px'},
        {label: 'Action', field: 'action', sortable: false},
      ],
      isLoading: true,
      totalRecords: undefined,
      statusList: [],
      params: {
        page: undefined,
        per_page: undefined,
        sort_keys: undefined,
        sort_dir: undefined,
        search: undefined,
      },
    }
  },
  watch: {
    params: {
      handler() {
        console.log(this.params)
        this.getProjects();
      },
      deep: true
    }
  },
  methods: {
    getProjects() {
      this.isLoading = true;
      this.projectService.index(this.params)
          .then(res => {
            res.data.forEach(row => {
              row.savingProjectStatus = false
            })
            this.rows = res.data;
            this.totalRecords = res.total;
          })
          .catch(err => {
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    getStatusList() {
      this.projectService.getProjectStatus()
          .then(res => {
            this.statusList = res.data;
          })
    },
    onPerPageChange(page) {
      this.params.page = page.currentPage;
      this.params.per_page = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.per_page = active.currentPerPage;
    },
    onSortChange(params) {
      console.log(params)
      const SortKey = typeof params[0].field === 'function' ? "created_at" : params[0].field;
      this.params.sort_keys = [SortKey];
      this.params.sort_dir = [params[0].type];
    },
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    dateFormat(objRow) {
      return new Date(objRow.created_at).toLocaleDateString() + ' ' + new Date(objRow.created_at).toLocaleTimeString()
    },
    getProjectStatusColor(name) {
      return this.statusList.find(status => {
        return status.name === name
      })?.color
    },
    changeOfferStatus({id}, name) {
      const index = this.rows.findIndex(row => row.id === id)
      this.rows[index].savingProjectStatus = true
      this.projectService.update(id, {status: name})
          .then(res => {
            console.log(res)
            this.rows[index].status = name
          })
          .finally(() => {
            this.rows[index].savingProjectStatus = false
          })
    },
    deleteProject(project) {
      console.log(project)
      this.projectService.delete(project)
          .then(res => {
            console.log(res)
            this.getProjects()
          })
    },
    showProjectDetailsModal(project) {
      console.log(project)
      this.$refs.ProjectDetailsModal.$data.projectDetailsId = project.project_details_id
      this.$refs.ProjectDetailsModal.$data.projectId = project.id
      this.$refs.ProjectDetailsModal.open();
    },
  },
  mounted() {
    this.getProjects()
    this.getStatusList()
  }
}
</script>

<style lang="scss" scoped>
.vgt-responsive {
  overflow-y: inherit;
}

::v-deep .dropdown-menu.show {
  overflow: auto;
  max-height: 150px
}

.avatar-class {
  background: #eee;
  padding: 5px;
  border-radius: 6px;
}
::v-deep ::-webkit-scrollbar{
  height: 10px;
}
</style>
