<template>
  <div class="card">
    <div class="card-body">
      <div class="">
        <h3 class="mb-2">Project Plan</h3>
      </div>
      <DropZone
          :classes="'col-6 col-sm-6 col-md-4 col-xl-2 mb-2'"
          :withNotes="true"
          :label="''"
          :maxSize="35"
          :hint="'Documents should be in jpg, jpeg, png, mp4, pdf, svg, ppt, pptx, xlx, xlsx, docx, doc, DWG'"
          :filesThumbnail="data.filesThumbnail"
          :filesIDs="data.files"
          @dataChanged="dataChanged"
      />
    </div>
  </div>
</template>

<script>
import DropZone from "@/views/components/file-uploader/DropZone";
import ProjectService from "@/services/ProjectService";

export default {
  name: "ProjectPlan",
  components: {
    DropZone
  },
  props: {
    projectDetailsId: null,
    attachments: []
  },
  data() {
    return {
      projectService: new ProjectService(),
      data: {
        files: [],
        filesThumbnail: [],
      },
    }
  },
  methods: {
    dataChanged(payload) {
      this.data.files = payload
      this.projectService.updatePlanLocation(this.projectDetailsId, {attachments: payload})
    },
  },
  mounted() {
    this.attachments.forEach(attach => {
      if (attach.media[0]) {
        this.data.files.push({file_id: attach.media[0].id, note: attach.note})
        this.data.filesThumbnail.push({...attach.media[0], note: attach.note})
      }
    })
  }
}
</script>

<style scoped>

</style>
