<template>
  <div class="row">
    <div class="col-12">
      <b-form-group
          :label="label"
          :label-cols-md="labelCols"
      >
        <small class="hint">{{ hint }}</small>
        <div :class="labelCols === 0 ? 'row' : ''">
          <div :class="classes"
               v-show="localFilesCount < maxFiles"
          >
            <vue-dropzone
                :useCustomSlot=true
                ref="myVueDropzone"
                class="my-dropzone"
                :style="customStyle"
                id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-upload-progress="reflectProgress"
                @vdropzone-success="vsuccess"
                @vdropzone-error="vdropzoneError"
                @vdropzone-file-added="vfileAdded"
                @vdropzone-max-files-reached="maxSizeError"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">+</h3>
              </div>
            </vue-dropzone>
          </div>
          <div
              v-for="(f , i) in filesThumbnail"
              :key="i"
              :class="classes">
            <div class="images-slider">
              <img
                  class="file-thumbnail"
                  :style="customStyle"
                  :src="f.original_url"
                  @error="$event.target.src= defaultThumb"
                  alt="">
              <feather-icon
                  icon="XIcon"
                  size="15"
                  @click="onRemove(f)"
              />
              <span v-if="f.name" class="file-name">
          {{ f.name }}
        </span>
              <div v-if="f.progress > 1 && f.progress <=100 && !f.id "
                   class="progress-style">
                <BProgress :value="f.progress" max="100"/>
              </div>
              <div v-if="f.error" v-html="f.error" class="bg-danger progress-style">
              </div>
              <div v-if="f.progress ===100 && f.id" class="bg-success progress-style">
                Done
              </div>
            </div>
            <b-form-input
                v-if="withNotes && f.id"
                size="sm"
                placeholder="Note"
                debounce="1000"
                @update="noteChanged($event,f)"
                v-model="f.note"
            />
          </div>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import config from "@/core/config/httpConfig"
import {BProgress} from 'bootstrap-vue'

export default {
  name: "DropZone",
  components: {
    vueDropzone: vue2Dropzone,
    BProgress
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelCols: {
      type: Number | String,
      default: 0
    },
    hint: {
      type: String,
      default: 'photos should be in jpg, jpeg, png, mp4, pdf, svg, ppt, pptx, xlx, xlsx, docx, doc, DWG'
    },
    classes: {
      type: String,
      default: 'col-6 col-sm-6 col-md-4 col-xl-3 mb-2'
    },
    withNotes: {
      type: Boolean,
      default: false
    },
    filesThumbnail: {
      type: Array,
      default: () => []
    },
    customStyle: {
      default: "",
      type: String
    },
    filesIDs: {
      type: Array,
      default: () => []
    },
    maxFiles: {
      type: Number,
      default: 10
    },
    maxSize: {
      type: Number,
      default: 15,
    },
    queryParam: {
      default: "hasan",
      type: String
    },
  },
  data() {
    return {
      localFilesCount: 0,
      dropzoneOptions: {
        timeout: 1000000,
        url: config.apiUrl + 'upload-media?' + this.queryParam,
        // thumbnailWidth: 150,
        paramName: 'files[]',
        maxFiles: this.maxFiles,
        // acceptedFiles: '/*',
        maxFilesize: this.maxSize, // MB
        headers: {"Authorization": 'Bearer ' + localStorage.getItem('accessToken')},
      },
      defaultThumb: require('@/assets/images/dropzone/Thumbnail.png')

    }
  },
  methods: {
    vfileAdded(file) {
      const reader = new FileReader()
      const myFile = {}
      console.log(file)
      console.log(file.status)
      let fileStatus = null
      if (this.maxFiles <= this.localFilesCount) {
        this.$refs.myVueDropzone.removeFile(file)
        fileStatus = 'Max files limit reached!'
      }
      this.localFilesCount++
      if (file.type.includes('image')) {
        const maxSize = this.maxSize
        setTimeout(() => {
          reader.addEventListener('load', function () {
            // convert image file to base64 string
            myFile.original_url = reader.result
            myFile.progress = 1
            myFile.id = null
            myFile.error = file.status === 'error' ?
                file.upload.total > maxSize * 1024 ?
                    `large file!<br>Max size ${maxSize}MB`
                    : fileStatus
                : fileStatus
          }, false)
          reader.readAsDataURL(file)
          setTimeout(() => {
            this.filesThumbnail.push(myFile)
          }, 50)
        }, 50)
      } else {
        const test = {
          original_url: require('@/assets/images/dropzone/Thumbnail.png'),
          progress: 1,
          id: null,
          // error: file.status === 'error' ? `${Index.t('large file!')}<br>${Index.t('Max size 4MB')}` : null,
          error: file.status === 'error' ?
              file.upload.total > 36700000 ?
                  `large file!<br>Max size 35MB`
                  : fileStatus
              : fileStatus,
          name: file.name,
          size: file.size,
        }
        console.log(test)
        this.filesThumbnail.push(test)
      }
    },
    reflectProgress(file, progress, bytesSent) {
      this.filesThumbnail.map(f => {
        if (file.type.includes('image')) {
          if (f.original_url === file.dataURL) {
            f.progress = progress
          }
        } else {
          if (f.name === file.name && f.size === file.size) {
            f.progress = progress
          }
        }
      })
    },
    vsuccess(file, response) {
      this.filesThumbnail.map(f => {
        // console.log(file)
        // console.log(file.type)
        if (file.type.includes('image')) {
          if (f.original_url === file.dataURL) {
            f.id = response.data[0].media[0].id
            if (this.withNotes) {
              f.note = ''
              this.filesIDs.push({file_id: response.data[0].media[0].id, note: ''})
            } else {
              this.filesIDs.push(response.data[0].media[0].id)
            }
            this.$emit('dataChanged', this.filesIDs)
          }
        } else {
          if (f.name === file.name && f.size === file.size) {
            f.id = response.data[0].media[0].id
            if (this.withNotes) {
              f.note = ''
              this.filesIDs.push({file_id: response.data[0].media[0].id, note: ''})
            } else {
              this.filesIDs.push(response.data[0].media[0].id)
            }
            this.$emit('dataChanged', this.filesIDs)
          }
        }

      })
    },
    maxSizeError(files) {
      //   console.log(files)
      //   console.log('this.$refs.myVueDropzone.dropzone.files', this.$refs.myVueDropzone.dropzone.files)
      //   console.error('maxSizeError', files)
      //   this.filesThumbnail.map(f => {
      //     files.forEach(file => {
      //       if (file.type.includes('image')) {
      //         if (f.original_url === file.dataURL) {
      //           console.warn('Max limit reached')
      //           f.error = Index.t('Max limit reached')
      //         }
      //       } else {
      //         if (f.name === file.name && f.size === file.size) {
      //           f.error = Index.t('Max limit reached')
      //         }
      //       }
      //     })
      //
      //   })
    },
    vdropzoneError(file, message) {
      console.log(file)
      console.log(message)
      this.filesThumbnail.map(f => {
        if (file.type.includes('image')) {
          if (f.original_url === file.dataURL) {
            f.error = message
          }
        } else {
          if (f.name === file.name && f.size === file.size) {
            f.error = message
          }
        }
      })
    },
    test(file) {
      console.log(file)
    },
    noteChanged(event, file) {
      this.filesIDs.forEach(fileObj => {
        if (fileObj.file_id === file.id) {
          fileObj.note = event
        }
      })
      this.$emit('dataChanged', this.filesIDs)
    },
    onRemove(file) {
      this.localFilesCount--
      this.filesThumbnail.splice(this.filesThumbnail.indexOf(file), 1)
      this.$refs.myVueDropzone.dropzone.files.splice(this.$refs.myVueDropzone.dropzone.files.indexOf(file), 1)
      if (this.withNotes) {
        const onlyIds = this.filesIDs
        if (onlyIds.map(e => e.file_id).indexOf(file.id) !== -1) {
          this.filesIDs.splice(onlyIds.map(e => e.file_id).indexOf(file.id), 1)
        }
      } else {
        if (this.filesIDs.indexOf(file.id) !== -1) {
          this.filesIDs.splice(this.filesIDs.indexOf(file.id), 1)
        }
      }
      this.$emit('dataChanged', this.filesIDs)
    }
  },
  mounted() {
    console.log('this.filesThumbnail.length', this.filesThumbnail.length)
    this.localFilesCount = this.filesThumbnail.length
  }
}
</script>

<style scoped>
/*@media (max-width: 1200px ) {*/
/*  .my-dropzone, .file-thumbnail{*/
/*    height: 100px;*/
/*  }*/
/*}*/
.bg-success {
  background: var(--primary) !important;
}

@media (max-width: 1281px) and  (min-width: 1200px) {
  .my-dropzone, .file-thumbnail {
    height: 90px;
  }
}

@media (max-width: 900px ) {
  .my-dropzone, .file-thumbnail {
    height: 80px;
  }
}
</style>
<style>
.dropzone .dz-message {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: transparent;
}

.dropzone .dz-message .dropzone-custom-title {
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  right: 0;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview {
  position: absolute !important;
  z-index: -10;
}

.images-slider {
  margin-bottom: 5px;
  /*margin-right: 1rem;*/
  position: relative;
  border-radius: 6px;
}

.images-slider svg {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 99;
  background: #EEE;
  padding: 0.3rem;
  border-radius: 20px;
  font-size: 10px;
  display: none;
  cursor: pointer;
}

.images-slider:hover svg {
  display: block;
}

.file-thumbnail {
  position: relative;
  height: 100px;
  opacity: .8;
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.file-thumbnail:hover {
  opacity: .7;
}

.images-slider:hover .file-name {
  z-index: 1;
}

.file-name {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  word-break: break-word;
  color: #fff;
  font-size: 12px;
  padding: 4px;
  width: 100%;
  background: rgba(128, 128, 128, 0.85);
  height: 100px;
  border-radius: 6px;
}

.progress-style {
  bottom: 0;
  border-radius: 6px;
  position: absolute;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #fff;
  z-index: 4;
}

.my-dropzone {
  display: flex;
  align-items: center;
  position: relative;
  min-height: unset;
  height: 100px;
  background: transparent;
  cursor: pointer;
  border-color: #C1C5D8;
  color: #717386;
  border-radius: 5px;
  font-size: 16px;
  overflow: hidden;
}

.my-dropzone img {
  width: 5rem;
  display: flex;
}

.hint {
  font-size: 11px;
  color: var(--gray);
  display: flex;
  margin-bottom: .5rem;
  margin-top: -.2rem;
}

</style>
