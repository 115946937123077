<template>
  <div class="card">
    <div class="card-body">
      <h3>Project Details</h3>
      <div class="">
        <div class="row">
          <div class="col-6">
            <b-form-group
                label="Offer Approved By Client"
                label-cols-md="4"
            >
              <validation-provider #default="{ errors }" name="Offer Approved By Client" rules="required">
                <v-select
                    label="name"
                    placeholder="Select Status"
                    :clearable="false"
                    class="status-selector"
                    :loading="statusListLoader"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :class="statusError ? 'border-danger-for-v-select' : ''"
                    :options="statusList"
                    :reduce="option=>option.name"
                    v-model="localProjectDetails.offer_status"
                    @input="checkAttachedFile"
                >
                  <template #option="{ name, color }" :style="{backgroundColor:color, color:'#fff'}">
                      <span class="d-flex option-style" :style="{backgroundColor:color, color:'#fff'}">
                        {{ name }}
                      </span>
                  </template>

                  <template #selected-option="{ name, color }" :style="{backgroundColor:color, color:'#fff'}">
                    <span class="d-flex pl-2 pr-2" :style="{backgroundColor:color, color:'#fff'}"> {{ name }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ statusError }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <DropZone
                v-if="media"
                :classes="''"
                :label="'Attach Signed Contract'"
                :labelCols="4"
                :filesThumbnail="media"
                :filesIDs="mediaId"
                :hint="''"
                :maxSize="30"
                :maxFiles="1"
                @dataChanged="dataChanged"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group
                label="Google Drive URL"
                label-cols-md="4"
            >
              <validation-provider
                  #default="{ errors }"
                  name="google drive URL"
                  rules="required|url"
              >
                <b-input-group>
                  <b-form-input
                      v-model="localDriveLink"
                      debounce="1000"
                      @update="updateDriveLink"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://drive.google.com/123"
                  />
                  <b-input-group-append>
                    <b-button
                        :href="'https://drive.google.com/'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        target="_blank"
                        class="btn-icon"
                    >
                      <feather-icon
                          icon="LinkIcon"
                          v-b-tooltip.hover
                          title="Google Drive"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
                label="Total Project Area"
                label-cols-md="4"
            >
              <b-form-input
                  disabled
                  :value="totalArea"
                  placeholder="Total Project Area"
              />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ProjectService from "@/services/ProjectService";
import DropZone from "@/views/components/file-uploader/DropZone";
import Ripple from "vue-ripple-directive";
import {VBTooltip} from "bootstrap-vue";

export default {
  name: "ProjectDetailsSection",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    DropZone
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    projectDetails: {},
    google_drive_link: null,
    totalArea: null,
    projectDetailsId: null,
    projectId: null
  },
  data() {
    return {
      projectService: new ProjectService(),
      statusList: [],
      statusListLoader: true,
      data: null,
      localProjectDetails: {},
      statusError: undefined,
      mediaId: [],
      media: null,
      localDriveLink: null
    }
  },
  methods: {
    getStatusList() {
      this.projectService.getProjectDetailsStatus()
          .then(res => {
            console.log(res)
            this.statusList = res.data
          })
          .finally(() => {
            this.statusListLoader = false
          })
    },
    dataChanged(payload) {
      this.mediaId = payload
      this.projectService.updateProjectDetails(this.projectDetailsId, {files: payload})
    },
    checkAttachedFile(event) {
      this.statusError = undefined
      console.log(event)
      console.log(this.mediaId[0])
      console.log('this.projectDetails.offer_status', this.projectDetails.offer_status)
      if (!this.mediaId[0] && event === 'Approved') {
        this.$nextTick(() => {
          this.localProjectDetails.offer_status = this.projectDetails.offer_status
          this.statusError = 'You need to add attach signed contract before approve'
        })
      } else {
        this.projectService.updateProjectDetails(this.projectDetailsId, {offer_status: event})
      }
    },
    updateDriveLink(event) {
      this.projectService.update(this.projectId, {google_drive_link: event})
    }
  },
  mounted() {
    console.log(this.projectDetails)
    this.getStatusList()
    if (this.projectDetails && this.projectDetails.media[0]) {
      this.mediaId = [this.projectDetails.media[0].id]
      this.media = this.projectDetails.media
    }
    this.localDriveLink = this.google_drive_link
    this.localProjectDetails = {...this.projectDetails}
  }
}
</script>

<style scoped lang="scss">
.option-style {
  padding: 5px;
  //border-radius: 0.358rem;
  justify-content: center;
}

::v-deep .status-selector .vs__dropdown-option {
  padding: 0;
  margin: 2px;
}
</style>
