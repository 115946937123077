<template>
  <div class="card">
    <div class="card-body">
      <h3>First Site Visit</h3>
      <div class="row">
        <div class="col-6">
          <b-form-group
              label="Due Date"
              label-cols-md="4"
          >
            <b-form-input
                disabled
                :value="localData.due_date"
            />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
              label="Assignee"
              label-cols-md="4"
          >
            <v-select
                placeholder="Select User"
                label="first_name"
                :clearable="false"
                :loading="usersListLoader"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="usersList"
                :reduce="option=>option.id"
                v-model="localData.assignee_id"
                @input="update($event,'assignee_id', localData.assignee_id)"
            >
              <template #option="{ first_name, last_name }">
                <b-avatar
                    size="26"
                    :text="avatarText(first_name + ' ' + last_name)"
                />
                <span class="ml-50 d-inline-block align-middle"> {{ first_name }}  {{ last_name }}</span>
              </template>

              <template #selected-option="{ first_name, last_name }">
                <b-avatar
                    size="20"
                    :text="avatarText(first_name + ' ' + last_name)"
                />
                <span class="ml-50 d-inline-block align-middle"> {{ first_name }}  {{ last_name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
              label="Visit Date"
              label-for="visit-date"
              label-cols-md="4"
          >
            <flat-pickr
                v-model="localData.visit_date"
                class="form-control"
                :config="flatConfig"
            />
            <div v-if="localData.visit_date" class="">
              <b-form-valid-feedback :state="checkDueDate">
                <strong>On Time</strong>
              </b-form-valid-feedback>
              <b-form-invalid-feedback :state="checkDueDate">
                <strong>Late</strong>
              </b-form-invalid-feedback>
            </div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
              label="Comments"
              label-for="comments"
              label-cols-md="4"
          >
            <b-form-textarea
                id="comments"
                v-model="localData.notes"
                debounce="3000"
                @update="update($event,'notes', localData.notes)"
                placeholder="Type your comments..."
            />
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ProjectService from "@/services/ProjectService";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "FirstSiteVisit",
  props: {
    data: null,
    projectDetailsId: null
  },
  data() {
    return {
      projectService: new ProjectService(),
      localData: this.data,
      usersListLoader: true,
      usersList: [],
      flatConfig: {
        minDate: "today",
        dateFormat: 'd/m/Y'
      }
    }
  },
  components: {
    vSelect,
    flatPickr
  },
  computed: {
    checkDueDate() {
      const visit_date = this.localData.visit_date.split('/')
      const due_date = this.localData.due_date.split('/')
      return new Date(`${visit_date[2]}/${visit_date[1]}/${visit_date[0]}`).toLocaleDateString() <= new Date(`${due_date[2]}/${due_date[1]}/${due_date[0]}`).toLocaleDateString()
    }
  },
  watch: {
    'localData.visit_date'(newVal) {
      const visit_date = this.localData.visit_date.split('/')
      this.update(newVal, 'visit_date', `${visit_date[2]}-${visit_date[1]}-${visit_date[0]}`)
    }
  },
  methods: {
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    getUsersList() {
      this.projectService.getUsersOptions()
          .then(res => {
            this.usersList = res.data
          })
          .finally(() => {
            this.usersListLoader = false
          })
    },
    update(event, key, value) {
      console.log(event, key, value)
      this.projectService.updateProjectDetails(this.projectDetailsId, {[key]: value})
    }
  },
  mounted() {
    this.getUsersList()
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
