<template>
  <b-card title="Projects Table">
    <div class="d-flex mb-1">
      <div class="">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class=""
                  variant="primary" :to="{ name: 'create-project' }">Start New Project
        </b-button>
      </div>
    </div>
    <div class="">
      <ProjectTable/>
    </div>
  </b-card>
</template>

<script>
import ProjectTable from "@/views/components/projects/ProjectTable";
import Ripple from "vue-ripple-directive";

export default {
  name: "Projects",
  directives: {
    Ripple
  },
  components: {ProjectTable}
}
</script>

<style scoped>

</style>
