<template>
  <div :class="{'skeleton-box': loading}">
    <div v-if="!editMood" class="cursor-pointer d-flex" @click="openEdit">
      <span>{{ value }}</span>
      <feather-icon class="ml-auto" icon="EditIcon"/>
    </div>
    <validation-provider
        #default="{ errors }"
        :rules="required ? 'required': ''"
    >
      <b-form-input v-if="editMood && !readonly" v-model="text" @focusout="focusOut"
                    type="text"
                    :autofocus="!required"
                    :disabled="readonly"
                    :state="isEmpty"
                    @keypress.enter="focusOut"
                    class="d-inline-block"
      />
      <small v-if="isEmpty === false && required" class="text-danger">Field required</small>
    </validation-provider>
  </div>
</template>

<script>
import {BFormInput} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from 'vee-validate'

export default {
  name: "InlineCellEdit",
  components: {
    BFormInput,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    value: '',
    loading: false,
    required: false,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: '',
      editMood: false
    }
  },
  computed: {
    isEmpty() {
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return null
      }
      console.log(this.text)
      return !this.text ? false : null;
    },
    isValidCode() {
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return true
      }
      console.log(this.colorSection.colorId)
      return this.colorSection.colorId;
    },
  },
  mounted() {
    this.text = this.value
    if (this.required) {
      this.editMood = true
    }
  },
  methods: {
    openEdit() {
      if (!this.loading) {
        this.editMood = true
      }
    },
    focusOut() {
      if (!this.required) {
        this.editMood = false
      }
      if (this.value !== this.text) {
        this.$emit('changed', this.text)
      }
    }
  }
}
</script>

<style scoped>

</style>
