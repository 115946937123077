<template>
  <div class="">
    <div class="">
      <div class="">
        <h6 class="bg-primary text-center text-white mb-0" style="padding: .5rem">
          {{
            typeName !== 'Single' ?
                typeName
                : 'Quantity'
          }}</h6>
      </div>
      <b-table
          :striped="true"
          :outlined="true"
          :items="value"
          :fixed="windowWidth > 500"
          responsive
          :fields="fields"
      >
        <template #cell(sand_blast)="data">
          <div :class="{'skeleton-box': data.item.savingSandBlast}">
            <b-form-checkbox
                :checked="data.item.sand_blast === 'Yes'"
                class="custom-control-primary"
                name="check-button"
                :disabled="data.item.savingSandBlast || readonly"
                @change="updateSandblast($event, data.item)"
                switch
            />
          </div>
        </template>
        <template #cell(plan_location)="data">
          <div :class="{'skeleton-box': data.item.savingPlanLocation}">
            <InlineCellEdit
                :required="true"
                :readonly="readonly"
                :loading="data.item.savingPlanLocation"
                @changed="updatePlanLocation($event , data.item)"
                :value="data.item.plan_location"/>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox, BTable} from "bootstrap-vue";
import OfferService from "@/services/OfferService";
import InlineCellEdit from "@/views/components/shared/InlineCellEdit";

export default {
  name: "TableList",
  components: {
    BTable,
    BFormCheckbox,
    InlineCellEdit
  },
  props: {
    value: {
      Type: undefined,
    },
    typeName: {
      Type: String,
    },
    windowWidth: {
      Type: Number
    },
    offerId: {
      Type: Number
    },
    readonly: {
      type: Boolean,
      default: false
    },
    withAreaCol: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      offerService: new OfferService(),
      tablesArray: {
        Front: [],
        Back: [],
        Left: [],
        Right: [],
        Single: []
      },
      fields: [
        {
          key: 'item_reference_code',
          label: 'Ref',
        },
        {
          key: 'sand_blast',
          label: 'Sandblast',
        },
        {
          key: 'item',
          label: 'Item Type',
          formatter: value => `${value.name} / ${value.product_category.name}`,
          class: 'custom-col-width-20'
        },
        {
          key: 'description.content',
          label: 'Description',
          class: 'custom-col-width-20'
        },
        {
          key: 'width',
          label: 'W',
        },
        {
          key: 'height',
          label: 'H',
        },
        {
          key: 'SQM',
          label: 'SQM',
        },
        {
          key: 'quantity',
          label: 'Qnt',
        },
        {
          key: 'plan_location',
          label: 'Plan Location',
          class: 'custom-col-width-15'
          // formatter: value => `${value.first} ${value.last}`
        },
      ],
    }
  },
  methods: {
    test(e) {
      console.log(e)
    },
    updateSandblast(bool, record) {
      if (record.savingSandBlast) {
        return
      }
      record.savingSandBlast = true
      record.sand_blast = bool ? 'Yes' : 'No'
      this.offerService.updateTableOfQuantity(this.offerId, {item_id: record.id, sand_blast: bool}).then(res => {
      }).finally(() => {
        record.savingSandBlast = false
      })
    },
    updatePlanLocation(text, record) {
      const oldValue = record.plan_location
      record.plan_location = text
      record.savingPlanLocation = true
      this.offerService.updateTableOfQuantity(this.offerId, {item_id: record.id, plan_location: text}).then(res => {
      }, err => {
        console.error(err)
        record.plan_location = oldValue
      }).finally(() => {
        record.savingPlanLocation = false
      })
    }
  },
  mounted() {
    if (this.withAreaCol) {
      this.fields.push({
        key: 'area_percentage',
        label: '% of Area',
      })
    }
  }
}
</script>

<style scoped>
::v-deep .custom-col-width-20 {
  width: 20%;
}

::v-deep .custom-col-width-15 {
  width: 15%;
}

@media (max-width: 1199px ) {
  ::v-deep .custom-col-width-20, ::v-deep .custom-col-width-15 {
    width: auto;
  }
}

.table-responsive {
  margin-bottom: 0px !important;
}
</style>
