import CrudService from "@/services/shared/http/CrudService";

export default class TicketService extends CrudService {
    constructor(params = {}) {
        super("projects", params);
    }

    getProjectStatus() {
        return this.api.GET('project-status')
    }

    getProjectDetailsStatus() {
        return this.api.GET('project-detail-status')
    }

    getProjectDetails(id) {
        return this.api.GET('project-details/' + id)
    }

    updateProjectDetails(id, params) {
        return this.api.PUT('project-details/' + id, params)
    }

    updatePlanLocation(id, params) {
        return this.api.PUT('project-plan-attachments/' + id, params)
    }

    getOffersList() {
        return this.api.GET('offer-references-list?progress=Accepted')
    }

    getUsersOptions(params = undefined) {
        return this.api.GET('users-list', params)
    }


}
